import React, {useState, useEffect} from "react";
import { useParams } from "react-router";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import ProductFeature from "components/features/TwoColSingleFeatureWithStats.js";
import Footer from "components/footers/SimpleFiveColumn";
import { products } from "../constants";

export default () => {
    const [product, setProduct] = useState();
    const { code } = useParams();

    useEffect(() => {
        let foundProduct;
        foundProduct = products["Everyday Ground Spices"].find(p => p.code === code);
        if(foundProduct === undefined){
            foundProduct = products["Whole Spices"].find(p => p.code === code);
        }
        setProduct(foundProduct);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

  return (
    <AnimationRevealPage>
      <Header />
      {product?<ProductFeature product={product} />:""}
      <Footer />
    </AnimationRevealPage>
  );
};
