import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import ProductCards from "components/cards/TabCardGrid";
import Footer from "components/footers/SimpleFiveColumn";

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <ProductCards />
      <Footer />
    </AnimationRevealPage>
  );
};
