import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-md`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
// const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm text-justify leading-loose`;
// const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/insiemeagro.appspot.com/o/07.png?alt=media&token=e84b0a8c-8c0f-4323-8e19-5603344d5f93",
      title: "Who we are",
      description:
        "It was the year 2014, with a mere investment and determination, a small agro firm was started in Kalyani - kolkata regions by Shri Ranjan Kumar Saha ( Microbiologist, chairperson of the director's board ) , the architect behind the empire - Insieme Agro group. After long 8 years, Insieme Agro has turned into a renowned FMCG industry with his two directors - Shri Abhijit Roy (Biochemist) & Shri Pulak Roy (Chef) to ensured the products which are offered to the consumers are of the top most quality and produce the brand ' AROMAL - nature's best ' as an epitome of purity. "
    },
    {
      imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/insiemeagro.appspot.com/o/02.png?alt=media&token=44e2a102-e104-4a39-8ff4-4a0504f1e4b2",
      title: "Our Philosophy",
      description:
        "The aim of Insieme Agro has always been maintaining the same standards of purity, freshness and authenticity in every pack, getting their aroma and flavor, consistently right. AROMAL's great taste of purity is based up with research, innovation and technology. The success lies in reaching the heart of Indian's culinary world with the spices and perfect blends. That's in a nutshell is the essence of ' AROMAL - nature's best' ......... Quality says everything",
    },

    {
      imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/insiemeagro.appspot.com/o/04.png?alt=media&token=9d9fc936-7d4e-453b-a8f1-7c25bcf1b2da",
      description:
        "Great quality brings great taste, is still Insieme's care philosophy. Adequate care is taken besides strict hygiene to preserve the essential goodness of the spices, rather used any kind of artificial colours or preservatives. Raw materials are carefully sourced from the best spice producing regions in India and their hygiene and quality are meticulously monitored throughout manufacturing process. Insieme employs the low temperature grinding ( LTG) process for grinding the spices , which is an ingenious manufacturing technique to retain the flavour and aroma of spices in their original state. All products are sourced, tested, processed, packed and sealed at the state-of-the-art factory in Kalyani, Nadia - West Bengal.",
    },

    {
      imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/insiemeagro.appspot.com/o/03.png?alt=media&token=b3e401e5-812d-4642-85a8-f40ffa871ac8",
      title: "Hygiene & Quality Assurance",
      description:
        "At the very outset, health & hygiene is always kept in our mind while producing edible products for consumers - with due care & caution during production and packing. We have been certified with sorts of quality certificate for food safety, health and environmental concern. Our company's vision in this regard will be more and extra care and caution to ensure health and hygiene of our products. Health of our consumers is our only motto.",
    },

    {
      imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/insiemeagro.appspot.com/o/06.png?alt=media&token=bedd8ce6-e2e9-494a-b34a-123b9f638321",
      title: "Herbal benefits of spices",
      description:
        "Several antioxidants, antitoxins, antimicrobial, antifungal properties of various spices add values to a healthy life. As a brand , AROMAL - nature's best  takes utmost care in selecting the raw materials. All herbs, spices & condiments that contributes to a healthy life are chosen delicately & carefully from highest quality farms. ",
    },

    {
      imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/insiemeagro.appspot.com/o/05.png?alt=media&token=fea84a18-5019-46d1-9813-5e0904d8357e",
      title: "Marketing Network",
      description:
        "Our marketing network has established a reputation for delivering top class services through Distributor channels, Whole selling, Super stockist, Mass Retailers, Co-op societies, Institutional sales and retail sales. Insieme Agro is royally building active customers. We upgrade us consistently time after time .",
    },
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>About Us</HeadingTitle>
          <HeadingDescription>
          " The years teach much which the days never know ....." 
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
