import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ContentWithPaddingXl, Container } from "components/misc/Layouts.js";
import { SectionHeading as Heading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`text-center`;
const Testimonials = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch`;
const TestimonialContainer = tw.div`mt-16 lg:w-1/3`;
const Testimonial = tw.div`px-4 text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img`w-20 h-20 rounded-full`;
const Quote = tw.blockquote`mt-5 text-gray-600 font-medium leading-loose`;
const CustomerName = tw.p`mt-5 text-gray-900 font-semibold uppercase text-sm tracking-wide`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

export default ({
  subheading = "Testimonials",
  heading = "Customer's Review",
  testimonials = [
    {
      imageSrc:
        "https://avatars.githubusercontent.com/u/93485900",
      quote:
        "When you have the Aromal name on a particular brand, you expect quality and this is what this product certainly matches up to. With food stuffs,one is generally careful and cannot make compromises. Good quality and I will easily recommend this. All products of Aromal I have tried have been good thus far.",
      customerName: "Paritosh Ghorui"
    },
    {
      imageSrc:
        "https://avatars.githubusercontent.com/u/58604532",
      quote:
        "The flavour, freshness,purity,aroma and packaging of this Garam Masala is very good and it meets the expectations of an Indian household. My family finds it to be much better than many of the traditional brands existing in the market and is price competitive. Certainly worth going for.",
      customerName: "Tirtharaj Ghosh"
    },
    {
      imageSrc:
        "https://avatars.githubusercontent.com/u/9017168",
      quote:
        "What are taste. The sachets preserves the Aroma in a scientific way. This really tells us that how much they work to maintain flavour for very very long time. The Essential oil present in spices easily wash off therefore sachet very well preserved it. Kudos to the makers. A must-have product for indian kitchens.",
      customerName: "Biswajit Rana"
    }
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        <Testimonials>
          {testimonials.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <Testimonial>
                <Image src={testimonial.imageSrc} />
                <Quote>"{testimonial.quote}"</Quote>
                <CustomerName>- {testimonial.customerName}</CustomerName>
              </Testimonial>
            </TestimonialContainer>
          ))}
        </Testimonials>
      </ContentWithPaddingXl>

      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
