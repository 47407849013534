import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../../images/logo.png";
import FssaiLogo from "../../images/fssai-logo.png"
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "../../images/instagram-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-xl font-black text-primary-500`;

const NoticeContainer = tw.div`flex flex-wrap justify-between`;

const LicenseNotice = tw.div`flex items-center justify-center mx-auto md:mx-0`;
const LicenseImage = tw.img`w-10`;
const LicenseText = tw.p`ml-2 text-sm sm:text-base py-4 mt-0 font-medium `;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto md:mx-0 md:mr-4 `;
const CopyrightNotice = tw.div`text-center text-sm sm:text-base py-4 mt-4 md:mt-0 font-medium `;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg src={LogoImage} />
            <LogoText>Insieme Agro Group</LogoText>
          </LogoContainer>
          <CompanyDescription>
            Charsarati , Kalyani <br/>Nadia, West Bengal - 741251<br/>
            Email : <Link href="mail:support@insiemeagro.com">support@insiemeagro.com</Link><br/>
            Phone : <Link href="tel:+919609791732">+919609791732</Link>
          </CompanyDescription>
          <SocialLinksContainer>
            <SocialLink target="_blank" href="https://www.facebook.com/insiemeagro21/">
              <FacebookIcon />
            </SocialLink>
            <SocialLink target="_blank" href="https://www.instagram.com/aromal_natures_best/">
              <InstagramIcon />
            </SocialLink>
            <SocialLink target="_blank" href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>
        </WideColumn>
        <Column>
          <ColumnHeading>Quick Links</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="#">Blog</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">FAQs</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Support</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">About Us</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Product</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="#">Log In</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Personal</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Business</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Team</Link>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Legal</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <Link href="#">GDPR</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Privacy Policy</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Terms of Service</Link>
            </LinkListItem>
            <LinkListItem>
              <Link href="#">Disclaimer</Link>
            </LinkListItem>
          </LinkList>
        </Column>
      </FiveColumns>
      <NoticeContainer>
        <LicenseNotice>
          <LicenseImage src={FssaiLogo} />
          <LicenseText>License No. : 12821012000214</LicenseText>
        </LicenseNotice>
        <CopyrightNotice>&copy; {new Date().getFullYear()} Insieme Agro Group. All Rights Reserved. Developed by <Link href="https://webircle.com" target="_blank">Webircle</Link>.
        </CopyrightNotice>
      </NoticeContainer>
    </Container>
  );
};
