export const products = {
    "Everyday Ground Spices": [
      {
        code: "turmeric-powder",
        imageSrc:
          "https://firebasestorage.googleapis.com/v0/b/insiemeagro.appspot.com/o/haldi.png?alt=media&token=684e7264-9005-47d1-b367-652af1f272e5",
        title: "Haldi powder or Turmeric Powder",
        description: "Turmeric, also known as Indian saffron or the golden spice. Aromal Turmeric powder is mainly famous for quality, its golden-yellow colour and balanced food value.",
        quantity: ["25 gm", "50 gm", "100 gm"],
        price: "Starting from Rs. 20",
        rating: "5.0",
        reviews: "87",
        url: "products/turmeric-powder",
        category: "Everyday Ground Spices"
      },
      {
        code: "red-chilli-powder",
        imageSrc:
          "https://firebasestorage.googleapis.com/v0/b/insiemeagro.appspot.com/o/lalmirch.png?alt=media&token=f8f8f543-ecde-4de6-b4a9-a3cc508e47c5",
        title: "Lal Mirch Powder or Red Chilli Powder",
        description: "We always collect the best quality Chilli from the agriculture farm so that it can maintain your blood pressure level and improve your healthy heart and yes obviously keep balanced spiciness in your special meal.",
        quantity: ["25 gm", "50 gm", "100 gm"],
        price: "Starting from Rs. 20",
        rating: "4.8",
        reviews: "32",
        url: "products/red-chilli-powder",
        category: "Everyday Ground Spices"
      },
      {
        code: "coriander-powder",
        imageSrc:
          "https://firebasestorage.googleapis.com/v0/b/insiemeagro.appspot.com/o/dhania.png?alt=media&token=847151bf-8bff-4f1c-9fd2-42d0fe19b7e6",
        title: "Dhania Powder or Coriander Powder",
        description: "In ayurvedic scriptures of Charaka Samhita categorizes coriander as a Trushnaprashamana and Sheetaprashmana (that which relieves excessive cold) herb Finally A pinch of Aromal Coriander powder makes your dish special and delicious.",
        quantity: ["25 gm", "50 gm", "100 gm"],
        price: "Starting from Rs. 20",
        rating: "4.9",
        reviews: "89",
        url: "products/coriander-powder",
        category: "Everyday Ground Spices"
      },
      {
        code: "cumin-powder",
        imageSrc:
          "https://firebasestorage.googleapis.com/v0/b/insiemeagro.appspot.com/o/jeera.png?alt=media&token=c66e37ec-32fa-4e59-8e16-4a47a76be44b",
        title: "Jeera Powder or Cumin Powder",
        description: "Aromal jeera powder well known for a warm earthy flavor and aroma with a bit of both sweetness and bitterness. Your best choice Aromal spice powder makes you kitchen master.",
        quantity: ["25 gm", "50 gm", "100 gm"],
        price: "Starting from Rs. 20",
        rating: "4.8",
        reviews: "12",
        url: "products/cumin-powder",
        category: "Everyday Ground Spices"
      },
    ],
    "Whole Spices": [
        {
            code: "whole-black-pepper",
            imageSrc:
              "https://firebasestorage.googleapis.com/v0/b/insiemeagro.appspot.com/o/whole-black%20pepper.png?alt=media&token=b3829630-6a93-4812-a9ee-7af8287bdc58",
            title: "Kali Mirch or Black Pepper",
            description: "AROMAL black pepper has a sharp, hot and biting taste, extensively used in the wintry cuisine of north-east India, to impart warmth and pungency to veg dishes & flavouring spice in non-veg preparations.",
            quantity: ["25 g", "50 g", "100 g", "1 kg"],
            price: "Starting from Rs. 20",
            rating: "4.8",
            reviews: "12",
            url: "products/whole-black-pepper",
            category: "Whole Spices"
          },
          {
            code: "whole-dhania",
            imageSrc:
              "https://firebasestorage.googleapis.com/v0/b/insiemeagro.appspot.com/o/whole-dhania.png?alt=media&token=fbe93234-836f-4d9d-97c6-fd9084727b57",
            title: "Dhania or Coriander",
            description: "Coriander seeds are used to flavour many foods, particularly curries, liqueurs and confectionery. Aromal dhania is a powerhouse of a variety of minerals and vitamins , which makes your dishes healthy & tasty.",
            quantity: ["25 g", "50 g", "100 g", "1 kg"],
            price: "Starting from Rs. 20",
            rating: "4.8",
            reviews: "12",
            url: "products/whole-dhania",
            category: "Whole Spices"
          },
          {
            code: "whole-garam-masala",
            imageSrc:
              "https://firebasestorage.googleapis.com/v0/b/insiemeagro.appspot.com/o/whole-garam%20masala.png?alt=media&token=979cd8ed-b164-415a-b5d8-a51478c881be",
            title: "Garam Masala",
            description: "Aromal whole garam masala is a spices that adds rich flavour and aroma required to make perfect dishes. Now you can make resturant style delicious food items at your home.",
            quantity: ["25 g", "50 g", "100 g", "1 kg"],
            price: "Starting from Rs. 20",
            rating: "4.8",
            reviews: "12",
            url: "products/whole-garam-masala",
            category: "Whole Spices"
          },
          {
            code: "whole-jeera",
            imageSrc:
              "https://firebasestorage.googleapis.com/v0/b/insiemeagro.appspot.com/o/whole-jeera.png?alt=media&token=c3059ed4-2d12-40dd-bd0e-3aaed4c176a5",
            title: "Jeera or Cumin",
            description: "Aromal jeera well known for warm earthly flavor and aroma with a bit of both sweetness and bitterness. Your best choice Aromal spice makes you kitchen master. ",
            quantity: ["25 g", "50 g", "100 g", "1 kg"],
            price: "Starting from Rs. 20",
            rating: "4.8",
            reviews: "12",
            url: "products/whole-jeera",
            category: "Whole Spices"
          },
    ],
    // Main: getRandomCards(),
    // Soup: getRandomCards(),
    // Desserts: getRandomCards()
};
